import { cn, Logo } from '@lemonenergy/lemonpie-components'
import type { ReactElement } from 'react'
import React, {
  Children,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import StoriesProgressBar from './StoriesProgressBar'
import type { StoryProps } from './Story'

const DEFAULT_STORY_DURATION = 10000

export type ThemeType = 'dark' | 'light'

const Stories = ({
  storyDuration = DEFAULT_STORY_DURATION,
  children,
}: {
  storyDuration?: number
  children: ReactElement<StoryProps> | ReactElement<StoryProps>[]
}): JSX.Element => {
  const [selectedStoryIndex, setSelectedStoryIndex] = useState(0)
  const animationFrameRef = useRef<number>()
  const startTimeRef = useRef<number>(Date.now())

  const storiesLength = Children.count(children)

  const animate = useCallback(() => {
    const elapsedTime = Date.now() - startTimeRef.current

    if (elapsedTime >= storyDuration) {
      setSelectedStoryIndex(
        (prevSelectedStoryIndex) =>
          (prevSelectedStoryIndex + 1) % storiesLength,
      )
      startTimeRef.current = Date.now()
    }

    animationFrameRef.current = requestAnimationFrame(animate)
  }, [storyDuration, storiesLength])

  useEffect(() => {
    animationFrameRef.current = requestAnimationFrame(animate)
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current)
      }
    }
  }, [animate])

  const storiesArray = Children.toArray(children) as ReactElement<StoryProps>[]

  const selectedStory = storiesArray[selectedStoryIndex]

  const { theme } = selectedStory.props

  const handleContainerClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()

      const containerWidth = event.currentTarget.clientWidth
      const clickX = event.clientX

      if (clickX < containerWidth / 2) {
        setSelectedStoryIndex((prevSelectedStoryIndex) =>
          prevSelectedStoryIndex === 0
            ? storiesLength - 1
            : prevSelectedStoryIndex - 1,
        )
      } else {
        setSelectedStoryIndex(
          (prevSelectedStoryIndex) =>
            (prevSelectedStoryIndex + 1) % storiesLength,
        )
      }
      startTimeRef.current = Date.now()
    },
    [storiesLength],
  )

  const logoColorStyle = theme === 'dark' ? `fg-inverted-main` : `fg-positive`
  return (
    <div
      className="relative m-0 flex flex-1"
      role="presentation"
      onClick={handleContainerClick}
    >
      <div className="absolute flex w-full flex-col gap-[9px] px-large py-0">
        <StoriesProgressBar
          storiesLength={storiesLength}
          selectedStory={selectedStoryIndex}
          theme={theme}
        />
        <Logo
          variant="symbol"
          className={cn('size-[2.5rem]', logoColorStyle)}
          size={40}
          color={theme === 'dark' ? '#FFFFFF' : '#008059'}
        />
      </div>
      {React.cloneElement(selectedStory, { theme })}
    </div>
  )
}
export default Stories
