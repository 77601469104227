import { cn } from '@lemonenergy/lemonpie-components'

import type { ThemeType } from './Stories'

const StoryProgress = ({
  isActive,
  isCompleted,
  theme,
}: {
  isActive: boolean
  isCompleted: boolean
  theme: ThemeType
}) => {
  const animation =
    isActive && !isCompleted ? 'animate-increaseProgressBar' : ''
  const transition = `transition-[${
    isActive && !isCompleted
      ? '10s cubic-bezier(0.2, 0, 0.8, 1) transform'
      : 'none'
  }]`
  const scale = isCompleted ? 'scale-x-100' : 'scale-x-0'
  const currentTheme = theme === 'dark' ? 'bg-main' : 'bg-expressive-strong'
  return (
    <div
      className={cn(
        'size-full flex-grow overflow-hidden rounded-smallest',
        theme === 'dark' ? 'bg-[#ffffff40]' : 'bg-soft-hover',
      )}
    >
      <div
        className={cn(
          'h-full origin-left scale-x-0 will-change-transform',
          currentTheme,
          scale,
          animation,
          transition,
        )}
      />
    </div>
  )
}

const StoriesProgressBar = ({
  storiesLength,
  selectedStory,
  theme,
}: {
  storiesLength: number
  selectedStory: number
  theme: ThemeType
}): JSX.Element => {
  const sequenceArray = Array.from(Array(storiesLength), (_, k) => k)
  return (
    <div className="flex h-[14px] w-full flex-row justify-center gap-smallest px-0 py-6">
      {sequenceArray.map((position) => (
        <StoryProgress
          key={`story-progress-${position}`}
          isActive={position === selectedStory}
          isCompleted={position < selectedStory}
          theme={theme}
        />
      ))}
    </div>
  )
}

export default StoriesProgressBar
